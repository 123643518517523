import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../../@types';
import usePermissions from '../../hooks/usePermissions';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

type Props = {
  permission: Permission;
};

const ProtectedPage = ({ permission }: Props) => {
  const hideBonusPage = useFeatureIsOn('hide-bonus-page');
  const { userId } = useAuth();
  const { hasPermission } = usePermissions();

  const isHiddenPage = hideBonusPage && (permission === 'manageBonuses' || permission === 'viewBonusReports');

  if (!userId || !hasPermission(permission) || isHiddenPage) {
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default ProtectedPage;
