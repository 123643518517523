(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@mui/material"), require("@emotion/styled"), require("@emotion/react"), require("keycloak-js"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@mui/material", "@emotion/styled", "@emotion/react", "keycloak-js"], factory);
	else if(typeof exports === 'object')
		exports["@neotech-solutions-org/neofusion-fe-shared"] = factory(require("react"), require("@mui/material"), require("@emotion/styled"), require("@emotion/react"), require("keycloak-js"));
	else
		root["@neotech-solutions-org/neofusion-fe-shared"] = factory(root["react"], root["@mui/material"], root["@emotion/styled"], root["@emotion/react"], root["keycloak-js"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__518__, __WEBPACK_EXTERNAL_MODULE__435__, __WEBPACK_EXTERNAL_MODULE__531__) => {
return 