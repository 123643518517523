import type { StructureResolver } from 'sanity/structure';
import { getSchemaTypes } from '.';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useEffect, useMemo, useState } from 'react';

const singletonSchemaTypeItemsName = ['CasinoBackgroundImage', 'Logo'];

export const useSanityStructure = () => {
  const user = useAuth();
  const [ready, setReady] = useState(false);
  const schemaTypes = useMemo(() => getSchemaTypes(user?.permissions), [user?.permissions]);

  const getStructure: StructureResolver = (S) => {
    const nonSingleSchemaTypes = schemaTypes.filter((item) => !singletonSchemaTypeItemsName.includes(item.name));
    const singletonSchemaTypes = schemaTypes.filter((item) => singletonSchemaTypeItemsName.includes(item.name));
    return S.list()
      .title('Content')
      .items([
        ...nonSingleSchemaTypes.map((item) => S.documentTypeListItem(item.name).title(item.title)),
        ...singletonSchemaTypes
          .reverse()
          .map((item) =>
            S.listItem()
              .title(item.title)
              .child(S.document().title(item.title).id(item.name).schemaType(item.name).documentId(item.name))
          ),
      ]);
  };

  useEffect(() => {
    if (schemaTypes && user?.permissions) {
      setReady(true);
    }
  }, [schemaTypes, user?.permissions, setReady]);
  return { getStructure, schemaTypes, ready };
};
