import { AccountCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import { drawerWidth } from '../molecules/DrawerNavigation';
import { useNavigate } from 'react-router-dom';
import MessageIcon from '../molecules/MessageIcon';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type HeaderProps = {
  open: boolean;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: theme.palette.primary.container,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  }),
}));

const styles: StyleObj = {
  iconButton: { mr: 2 },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 900,
  },
  accountButton: {
    p: 0,
    m: 0,
  },
  container: {
    minWidth: 120,
  },
  menuList: {
    '& .MuiList-root': {
      p: 0,
    },
    '& .MuiMenuItem-root': {
      px: 1.25,
      fontWeight: 600,
      ':hover': {
        backgroundColor: 'background.lighter',
      },
    },
  },
};

const Header = ({ open }: HeaderProps) => {
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenAccountMenu(true);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
    setOpenAccountMenu(false);
  };

  const handleLogoutClick = () => {
    handleLogout();
    handleAccountMenuClose();
  };

  return (
    <AppBar position='fixed' open={open}>
      <Toolbar>
        <Typography color='primary' variant='h2' component='div' sx={styles.title}>
          NTS 360
        </Typography>
        <MessageIcon />
        <IconButton
          size='large'
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          edge='end'
          color='inherit'
          aria-label='menu'
          sx={styles.accountButton}
          onClick={handleAccountMenuOpen}
        >
          <AccountCircle color='primary' />
        </IconButton>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        open={openAccountMenu}
        onClose={handleAccountMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -10, horizontal: 'center' }}
        elevation={3}
        MenuListProps={{
          disablePadding: true,
          sx: styles.menuList,
        }}
        slotProps={{
          paper: {
            sx: styles.container,
          },
        }}
      >
        <MenuList>
          <MenuItem onClick={() => navigate('/my-wallet')}>Wallet</MenuItem>
          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </AppBar>
  );
};

export default Header;
