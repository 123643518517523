import { COLUMN_FIELDS } from '../constants/table';

export const defaultColumnsSports = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: false,
  [COLUMN_FIELDS.POSITION]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CASHOUT]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsCompetitions = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.SPORT_ICON]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: false,
  [COLUMN_FIELDS.POSITION]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CASHOUT]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsTournaments = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.SPORT_ICON]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: false,
  [COLUMN_FIELDS.TOP_LEAGUE]: false,
  [COLUMN_FIELDS.TOP_OFFER]: false,
  [COLUMN_FIELDS.TOP_OFFER_POSITION]: false,
  [COLUMN_FIELDS.FEATURED]: false,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CASHOUT]: true,
  [COLUMN_FIELDS.INFORMATION]: false,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsParticipants = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: false,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.SPORT_ICON]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsPlayers = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.FIRST_NAME]: true,
  [COLUMN_FIELDS.LAST_NAME]: true,
  [COLUMN_FIELDS.DISPLAY_NAME]: false,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.SPORT_ICON]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: false,
  [COLUMN_FIELDS.PARTICIPANT_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsMarkets = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.SPORT_ICON]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: true,
  [COLUMN_FIELDS.USED_FOR]: true,
  [COLUMN_FIELDS.TYPE]: true,
  [COLUMN_FIELDS.POSITION]: true,
  [COLUMN_FIELDS.DESCRIPTION]: false,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CASHOUT]: false,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsMarketGroups = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.SPORT_ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.POSITION]: true,
  [COLUMN_FIELDS.USED_FOR]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.DISPLAY_TYPE]: true,
};

export const defaultColumnsOutcomes = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.MARKET_NAME]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.SHORT_NAME]: false,
  [COLUMN_FIELDS.POSITION]: true,
  [COLUMN_FIELDS.DESCRIPTION]: false,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsEvents = {
  [COLUMN_FIELDS.SPORT_ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.START_DATE]: true,
  [COLUMN_FIELDS.START_TIME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CASHOUT]: true,
  [COLUMN_FIELDS.FEATURED]: false,
  [COLUMN_FIELDS.EDIT]: true,
  [COLUMN_FIELDS.INFORMATION]: false,
};
export const getDefaultColumnsLiveEvents = () => {
  const { [COLUMN_FIELDS.FEATURED]: _, ...columnsWithoutFeatured } = defaultColumnsEvents;
  return columnsWithoutFeatured;
};

export const defaultColumnsCustomers = {
  [COLUMN_FIELDS.FIRST_NAME]: true,
  [COLUMN_FIELDS.LAST_NAME]: true,
  [COLUMN_FIELDS.USERNAME]: false,
  [COLUMN_FIELDS.COUNTRY]: true,
  [COLUMN_FIELDS.PHONE_NUMBER]: true,
  [COLUMN_FIELDS.EMAIL]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CREATED_AT]: true,
  [COLUMN_FIELDS.LAST_ONLINE]: true,
  [COLUMN_FIELDS.ADDRESS]: false,
  [COLUMN_FIELDS.ZIP_CODE]: false,
  [COLUMN_FIELDS.CITY]: false,
  [COLUMN_FIELDS.REGION]: false,
  [COLUMN_FIELDS.LANGUAGE]: false,
  [COLUMN_FIELDS.DATE_OF_BIRTH]: false,
  [COLUMN_FIELDS.GENDER]: false,
  [COLUMN_FIELDS.PARENT]: false,
  [COLUMN_FIELDS.CREATED_BY]: false,
  [COLUMN_FIELDS.NATIONAL_ID]: false,
  [COLUMN_FIELDS.CURRENCY]: false,
  [COLUMN_FIELDS.EDIT]: true,
  [COLUMN_FIELDS.CASHOUT]: true,
};

export const defaultColumnsPartners = {
  [COLUMN_FIELDS.FULL_NAME]: true,
  [COLUMN_FIELDS.EMAIL]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.CREATED_AT]: true,
  [COLUMN_FIELDS.PARENT]: true,
};

export const defaultColumnsResults = {
  [COLUMN_FIELDS.SPORT_ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.MODEL_MATCHES]: true,
  [COLUMN_FIELDS.PUBLISH_TIME_MATCHES]: true,
  [COLUMN_FIELDS.MODEL_OUTRIGHTS]: true,
  [COLUMN_FIELDS.PUBLISH_TIME_OUTRIGHTS]: true,
};

export const defaultColumnsResultsAndResolve = {
  [COLUMN_FIELDS.SPORT_ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.START_TIME]: true,
  [COLUMN_FIELDS.RESULT]: true,
};

export const defaultColumnsConditionTypes = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.ENTITY]: true,
  [COLUMN_FIELDS.DESCRIPTION]: false,
  [COLUMN_FIELDS.RESTRICTION_MESSAGE_ADMIN]: false,
  [COLUMN_FIELDS.RESTRICTION_MESSAGE_CLIENT]: false,
  [COLUMN_FIELDS.VERIFICATION_MESSAGE_ADMIN]: false,
  [COLUMN_FIELDS.VERIFICATION_MESSAGE_CLIENT]: false,
};

export const defaultColumnsConditionGroups = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.ENTITY]: true,
  [COLUMN_FIELDS.DESCRIPTION]: false,
};

export const defaultColumnsSportConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: false,
  [COLUMN_FIELDS.DESCRIPTION]: false,
};

export const defaultColumnsTournamentConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
};

export const defaultColumnsCompetitionConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
};

export const defaultColumnsPunterConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.FIRST_NAME]: true,
  [COLUMN_FIELDS.LAST_NAME]: true,
  [COLUMN_FIELDS.USERNAME]: true,
  [COLUMN_FIELDS.STATUS]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
};

export const defaultColumnsMarketConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
};

export const defaultColumnsTicketConditions = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.CONDITION_GROUP_NAME]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
};

export const defaultColumnsBettingSlips = {
  [COLUMN_FIELDS.TICKET_ID]: true,
  [COLUMN_FIELDS.FROM]: true,
  [COLUMN_FIELDS.USER]: true,
  [COLUMN_FIELDS.USERNAME]: true,
  [COLUMN_FIELDS.BETTING_OPERATOR]: false,
  [COLUMN_FIELDS.PRE_MATCH_RISK_FACTOR]: false,
  [COLUMN_FIELDS.IN_PLAY_RISK_FACTOR]: false,
  [COLUMN_FIELDS.CREATED_AT]: true,
  [COLUMN_FIELDS.PAID_OUT_AT]: false,
  [COLUMN_FIELDS.TOTAL_STAKE_AMOUNT]: true,
  [COLUMN_FIELDS.TOTAL_ODDS]: true,
  [COLUMN_FIELDS.POSSIBLE_WINNINGS]: false,
  [COLUMN_FIELDS.WINNINGS]: true,
  [COLUMN_FIELDS.COMBINATIONS_COUNT]: false,
  [COLUMN_FIELDS.CANCELED]: false,
  [COLUMN_FIELDS.REOFFERED]: false,
  [COLUMN_FIELDS.ACCEPT_STATUS]: true,
  [COLUMN_FIELDS.RESOLUTION_STATUS]: true,
  [COLUMN_FIELDS.SOURCE_IP]: false,
  [COLUMN_FIELDS.ACTIONS]: false,
  [COLUMN_FIELDS.CASHOUT]: true,
};

export const defaultColumnsTicketAuthorization = {
  [COLUMN_FIELDS.TICKET_ID]: true,
  [COLUMN_FIELDS.CREATED_AT]: true,
  [COLUMN_FIELDS.TOTAL_STAKE_AMOUNT]: true,
  [COLUMN_FIELDS.TOTAL_ODDS]: false,
  [COLUMN_FIELDS.POSSIBLE_WINNINGS]: true,
  [COLUMN_FIELDS.BETS_COUNT]: true,
  [COLUMN_FIELDS.FROM]: false,
  [COLUMN_FIELDS.SOURCE_TYPE]: false,
  [COLUMN_FIELDS.USER]: false,
  [COLUMN_FIELDS.USERNAME]: true,
};

export const defaultColumnsTicketAuthorizationDetails = {
  [COLUMN_FIELDS.START_DATE]: true,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.FROM]: false,
  [COLUMN_FIELDS.EVENT]: true,
  [COLUMN_FIELDS.BANKER]: false,
  [COLUMN_FIELDS.IS_WAYS]: false,
  [COLUMN_FIELDS.MARKET]: true,
  [COLUMN_FIELDS.SPECIAL_VALUE]: true,
  [COLUMN_FIELDS.OUTCOME]: true,
  [COLUMN_FIELDS.ODDS]: true,
  [COLUMN_FIELDS.RESULT]: false,
};

export const defaultColumnsSportsRiskManagement = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.RISK_FACTOR_ID]: true,
  [COLUMN_FIELDS.RISK_FACTOR_NAME]: false,
  [COLUMN_FIELDS.RISK_FACTOR_VALUE]: false,
  [COLUMN_FIELDS.MIN_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.MAX_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_NAME]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_VALUE]: true,
  [COLUMN_FIELDS.LIABILITY_CALCULATION]: false,
  [COLUMN_FIELDS.DEFAULT_RISK_FACTOR]: false,
  [COLUMN_FIELDS.DEFAULT_MIN_TRIGGER]: false,
  [COLUMN_FIELDS.DEFAULT_MAX_TRIGGER]: false,
};

export const defaultColumnsTournamentsRiskManagement = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.COMPETITION_ID]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: true,
  [COLUMN_FIELDS.TOURNAMENT_ID]: false,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.RISK_FACTOR_ID]: false,
  [COLUMN_FIELDS.RISK_FACTOR_NAME]: false,
  [COLUMN_FIELDS.RISK_FACTOR_VALUE]: false,
  [COLUMN_FIELDS.MIN_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.MAX_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_ID]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_NAME]: false,
  [COLUMN_FIELDS.LIABILITY_LIMIT_VALUE]: false,
  [COLUMN_FIELDS.LIABILITY_CALCULATION]: false,
  [COLUMN_FIELDS.DEFAULT_RISK_FACTOR]: false,
  [COLUMN_FIELDS.DEFAULT_MIN_TRIGGER]: false,
  [COLUMN_FIELDS.DEFAULT_MAX_TRIGGER]: false,
};

export const defaultColumnsMarketsRiskManagement = {
  [COLUMN_FIELDS.SPORT_ID]: false,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: true,
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.MARKET_NAME]: true,
  [COLUMN_FIELDS.RISK_FACTOR_ID]: true,
  [COLUMN_FIELDS.RISK_FACTOR_NAME]: false,
  [COLUMN_FIELDS.RISK_FACTOR_VALUE]: false,
  [COLUMN_FIELDS.LIABILITY_LIMIT_ID]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_NAME]: false,
  [COLUMN_FIELDS.LIABILITY_LIMIT_VALUE]: false,
  [COLUMN_FIELDS.LIABILITY_CALCULATION]: false,
  [COLUMN_FIELDS.DEFAULT_RISK_FACTOR]: false,
  [COLUMN_FIELDS.MIN_VERIFICATION_TRIGGER]: false,
  [COLUMN_FIELDS.MAX_VERIFICATION_TRIGGER]: false,
};

export const defaultColumnsPlayersRiskManagement = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.FULL_NAME]: false,
  [COLUMN_FIELDS.USERNAME]: true,
  [COLUMN_FIELDS.RISK_FACTOR_ID]: true,
  [COLUMN_FIELDS.RISK_FACTOR_NAME]: false,
  [COLUMN_FIELDS.RISK_FACTOR_VALUE]: false,
  [COLUMN_FIELDS.MIN_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.MAX_VERIFICATION_TRIGGER]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_ID]: true,
  [COLUMN_FIELDS.LIABILITY_LIMIT_NAME]: false,
  [COLUMN_FIELDS.LIABILITY_LIMIT_VALUE]: false,
  [COLUMN_FIELDS.LIABILITY_CALCULATION]: false,
  [COLUMN_FIELDS.SETTINGS]: true,
  [COLUMN_FIELDS.DEFAULT_RISK_FACTOR]: false,
  [COLUMN_FIELDS.DEFAULT_MIN_TRIGGER]: false,
  [COLUMN_FIELDS.DEFAULT_MAX_TRIGGER]: false,
};

export const defaultColumnsLiabilityMonitoring = {
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: true,
  [COLUMN_FIELDS.EVENT]: true,
  [COLUMN_FIELDS.TOP_LIABILITY]: true,
  [COLUMN_FIELDS.LIABILITY_PERCENTAGE]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.COMPETITION_ID]: false,
  [COLUMN_FIELDS.TOURNAMENT_ID]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
  [COLUMN_FIELDS.EVENT_ID]: false,
  [COLUMN_FIELDS.UNIQUE_ID]: false,
  [COLUMN_FIELDS.START_DATE]: false,
  [COLUMN_FIELDS.LIMIT_ID]: false,
  [COLUMN_FIELDS.LIMIT_VALUE]: false,
  [COLUMN_FIELDS.RISK_FACTOR]: false,
  [COLUMN_FIELDS.NUMBER_TICKET_BETS]: false,
};

export const defaultColumnsTicketDetails = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.START_DATE]: true,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.FROM]: true,
  [COLUMN_FIELDS.BANKER]: false,
  [COLUMN_FIELDS.EVENT]: true,
  [COLUMN_FIELDS.MARKET]: true,
  [COLUMN_FIELDS.SPECIAL_VALUE]: true,
  [COLUMN_FIELDS.OUTCOME]: true,
  [COLUMN_FIELDS.IS_WAYS]: false,
  [COLUMN_FIELDS.ODDS]: true,
  [COLUMN_FIELDS.STATUS]: true,
  [COLUMN_FIELDS.RESULT]: false,
  [COLUMN_FIELDS.TOURNAMENT_NAME]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
};

export const defaultColumnsJackpots = {
  [COLUMN_FIELDS.ID]: true,
  [COLUMN_FIELDS.ICON]: true,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.TYPE]: true,
  [COLUMN_FIELDS.PRODUCT]: true,
  [COLUMN_FIELDS.WINNING_AMOUNT]: true,
  [COLUMN_FIELDS.STAKE_RANGES]: true,
  [COLUMN_FIELDS.WINNING_TICKETS]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.DESCRIPTION]: true,
  [COLUMN_FIELDS.EDIT]: true,
};

export const defaultColumnsOutrights = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.SPORT_ICON]: true,
  [COLUMN_FIELDS.SPORT_NAME]: false,
  [COLUMN_FIELDS.COMPETITION_NAME]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.START_DATE]: true,
  [COLUMN_FIELDS.START_TIME]: true,
  [COLUMN_FIELDS.IS_ACTIVE]: true,
  [COLUMN_FIELDS.EDIT]: true,
  [COLUMN_FIELDS.INFORMATION]: false,
};

export const defaultColumnsBonuses = {
  [COLUMN_FIELDS.ID]: false,
  [COLUMN_FIELDS.NAME]: true,
  [COLUMN_FIELDS.TYPE]: true,
  [COLUMN_FIELDS.PRODUCT]: true,
  [COLUMN_FIELDS.STATUS]: true,
  [COLUMN_FIELDS.DESCRIPTION]: false,
  [COLUMN_FIELDS.EDIT]: true,
};
